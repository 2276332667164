<template>
  <TagbyActionCard>
    <template #left>
      <CreateButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import {
} from 'bootstrap-vue'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
import CreateButton from './CreateButton.vue'

export default {
  components: {
    TagbyActionCard,
    CreateButton,
  },
}
</script>
