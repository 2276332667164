import {
  ref,
  onUnmounted,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useToast from '@/utils/toast'
import useStore from '@/utils/store'
import useResolveValue from '@/utils/resolveValue'
import uploadFile from '@/utils/uploadFile'

import appWidgetStoreModule from '@/store/tagby/appWidget'

export function useInitialize() {
  const initialize = () => {
    if (!store.hasModule(appWidgetStoreModule.STORE_MODULE_NAME)) {
      store.registerModule(appWidgetStoreModule.STORE_MODULE_NAME, appWidgetStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(appWidgetStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(appWidgetStoreModule.STORE_MODULE_NAME)
      }
    })
  }
  return {
    initialize,
  }
}

const appWidget = ref({
  name: null,
  state: 'ACTIVE',
  type: 'HOME',
  start_at: null,
  end_at: null,
  content: {
    title_text: '',
    link_text: '',
    thumb_url: '',
    route: {
      route_name: ['CampaignList'],
      params: [{
        api_endpoint: '/app-widget/recommend-campaign-list',
      }],
    },
  },
})
export function useDetail() {
  const stateOptions = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ]
  const typeOptions = [
    { label: 'HOME', value: 'HOME' },
    { label: 'MARKET', value: 'MARKET' },
    { label: 'RECOMMEND_CAMPAIGN', value: 'RECOMMEND_CAMPAIGN' },
  ]

  return {
    appWidget,
    stateOptions,
    typeOptions,
  }
}

const appWidgetContent = ref(JSON.stringify(appWidget.value.content, null, 4))
export function useAppWidgetContent() {
  const { makeToast } = useToast()
  const contentType = computed(() => {
    const tableName = appWidget.value.content?.table_name
    if (tableName === 'campaign_info') {
      return 'CAMPAIGN_LIST'
    }
    if (tableName === 'product_info') {
      return 'PRODUCT_LIST'
    }
    return 'SIMPLE'
  })
  const contentTypeOptions = [
    { label: 'SIMPLE', value: 'SIMPLE' },
    { label: 'PRODUCT_LIST', value: 'PRODUCT_LIST' },
    { label: 'CAMPAIGN_LIST', value: 'CAMPAIGN_LIST' },
  ]

  const inputContentType = value => {
    if (value === 'SIMPLE') {
      appWidget.value.content = {
        title_text: '',
        link_text: '',
        thumb_url: '',
        route: {
          route_name: ['CampaignList'],
          params: [{
            api_endpoint: '/app-widget/recommend-campaign-list',
          }],
        },
      }
    } else if (value === 'PRODUCT_LIST') {
      appWidget.value.content = {
        table_name: 'product_info',
        idx_list: [],
      }
    } else if (value === 'CAMPAIGN_LIST') {
      appWidget.value.content = {
        table_name: 'campaign_info',
        idx_list: [],
      }
    }
    appWidgetContent.value = JSON.stringify(appWidget.value.content, null, 4)
  }

  const isValid = computed(() => {
    try {
      JSON.parse(appWidgetContent.value)
      return true
    } catch {
      return false
    }
  })

  const blurAppWidgetContent = () => {
    if (isValid.value) {
      appWidget.value.content = JSON.parse(appWidgetContent.value)
    } else {
      makeToast('danger', '유효하지 않은 내용값입니다')
    }
  }

  const file = ref()
  async function inputFile(value) {
    const fileInfo = await uploadFile(value, 'app_widget_info')
    appWidget.value.content.thumb_url = fileInfo.file_url
    appWidgetContent.value = JSON.stringify(appWidget.value.content, null, 4)
  }

  return {
    contentType,
    contentTypeOptions,
    inputContentType,
    isValid,
    appWidgetContent,
    blurAppWidgetContent,
    file,
    inputFile,
  }
}

export function useCreateAppWidget() {
  const { makeToast } = useToast()
  const { dispatch } = useStore(appWidgetStoreModule.STORE_MODULE_NAME)
  const { resolveUtcDateTime } = useResolveValue()

  const isSaving = ref(false)
  const isValid = computed(() => {
    const requiredFields = ['name', 'state', 'type']
    return requiredFields.map(f => Boolean(appWidget.value[f])).reduce((p, c) => p && c, true)
  })
  const create = () => {
    isSaving.value = true
    const params = {
      name: appWidget.value.name,
      start_at: resolveUtcDateTime(appWidget.value.start_at),
      end_at: resolveUtcDateTime(appWidget.value.end_at),
      state: appWidget.value.state,
      type: appWidget.value.type,
      content: JSON.stringify(appWidget.value.content),
    }

    dispatch('create', params)
      .then(response => {
        makeToast('primary', '저장에 성공했습니다.')
        router.replace({
          name: 'app-widget-item-group-detail',
          params: { idx: response.data.data.idx },
        }).catch(() => {})
      }).catch(() => {
        makeToast('danger', '저장에 실패했습니다.')
      }).finally(() => {
        isSaving.value = false
      })
  }

  return {
    isSaving,
    isValid,
    create,
  }
}

// export default function () {
//   const APP_STORE_MODULE_NAME = 'app-widget-banner-create'

//   const { makeToast } = useToast()
//   const { resolveUtcDateTime } = useResolveValue()
//   const storeDispatch = (name, params) => store.dispatch(`${APP_STORE_MODULE_NAME}/${name}`, params)

//   const params = ref({
//     name: null,
//     state: 'ACTIVE',
//     type: 'MARKET',
//     start_at: null,
//     end_at: null,
//     table_name: 'product_info',
//   })

//   const isSaving = ref(false)
//   const isValid = computed(() => {
//     const requiredFields = ['name', 'state', 'type', 'start_at', 'end_at']
//     return requiredFields.map(f => Boolean(params.value[f])).reduce((p, c) => p && c, true)
//   })
//   const stateOptions = [
//     { label: 'ACTIVE', value: 'ACTIVE' },
//     { label: 'INACTIVE', value: 'INACTIVE' },
//   ]
//   const typeOptions = [
//     { label: 'HOME', value: 'HOME' },
//     { label: 'MARKET', value: 'MARKET' },
//   ]
//   const tableNameOptions = [
//     { label: '상품', value: 'product_info' },
//     { label: '캠페인', value: 'campaign_info' },
//   ]
//   const isUploadingFile = ref(false)
//   const create = () => {
//     isSaving.value = true
//     storeDispatch('create', {
//       ...params.value,
//       start_at: resolveUtcDateTime(params.value.start_at),
//       end_at: resolveUtcDateTime(params.value.end_at),
//     }).then(response => {
//       makeToast('primary', '배너 생성에 성공했습니다.')
//       router.replace({
//         name: 'app-widget-item-group-detail',
//         params: { idx: response.data.data.idx },
//       }).catch(() => {})
//     }).catch(() => {
//       makeToast('danger', '배너 생성에 실패했습니다.')
//     }).finally(() => {
//       isSaving.value = false
//     })
//   }

//   return {
//     params,
//     isSaving,
//     isValid,
//     stateOptions,
//     typeOptions,
//     isUploadingFile,
//     create,
//     tableNameOptions,
//   }
// }
