<template>
  <BCard title="CONTENT">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="TYPE">
          <VSelect
            :value="contentType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="contentTypeOptions"
            :reduce="option => option.value"
            @input="inputContentType"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="contentType === 'SIMPLE'"
        cols="4"
      >
        <BFormGroup label="IMAGE">
          <BFormFile
            v-model="file"
            accept="image/*"
            @input="inputFile"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="RAW">
          <BFormTextarea
            v-model="appWidgetContent"
            :state="isValid"
            :rows="10"
            @blur="blurAppWidgetContent"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { useAppWidgetContent } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    VSelect,
  },
  setup() {
    return {
      ...useAppWidgetContent(),
    }
  },
})
</script>
