<template>
  <TagbyDetailLayout>
    <template #content>
      <AppWidgetInfoCard />
      <ContentCard />
    </template>
    <template #side>
      <ActionCard />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import {
} from 'bootstrap-vue'
import { useInitialize } from './viewModel'

import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import AppWidgetInfoCard from './components/AppWidgetInfoCard.vue'
import ContentCard from './components/ContentCard.vue'
import ActionCard from './components/ActionCard.vue'

export default {
  components: {
    TagbyDetailLayout,
    AppWidgetInfoCard,
    ContentCard,
    ActionCard,
  },
  setup() {
    const { initialize } = useInitialize()
    initialize()
  },
}
</script>
