<template>
  <TagbyActionButton
    variant="outline-primary"
    text="저장"
    :isSaving="isSaving"
    :isValid="isValid"
    @click="create"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import {
  useCreateAppWidget,
} from '../viewModel'
import TagbyActionButton from '@/components/TagbyActionButton.vue'

export default defineComponent({
  components: {
    BButton,
    BSpinner,
    TagbyActionButton,
  },
  setup() {
    const {
      create,
      isSaving,
      isValid,
    } = useCreateAppWidget()

    return {
      create,
      isSaving,
      isValid,
    }
  },
})
</script>
