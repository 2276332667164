<template>
  <BCard title="상품그룹 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="IDX">
          <BFormInput
            :value="appWidget.idx"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="START_AT">
          <TagbyDatetimeInputV2
            v-model="appWidget.start_at"
            :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="END_AT">
          <TagbyDatetimeInputV2
            v-model="appWidget.end_at"
            :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol cols="4">
        <BFormGroup label="NAME">
          <BFormInput
            v-model="appWidget.name"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="STATE">
          <vSelect
            v-model="appWidget.state"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="stateOptions"
            :reduce="option => option.value"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="TYPE">
          <vSelect
            v-model="appWidget.type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeOptions"
            :reduce="option => option.value"
          />
          <!-- <BFormInput
            v-model="appWidget.type"
          /> -->
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import { useDetail } from '../viewModel'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
    TagbyDatetimeInputV2,
  },
  setup() {
    return {
      ...useDetail(),
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
