import axios from '@axios'

const STORE_MODULE_NAME = 'compt'

const actions = {
  fetchDetail(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/app-widget/${idx}/`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchTimelines(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/app-widget/${idx}/timelines/`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  update(ctx, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/app-widget/${idx}/update/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchItem(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/app-widget/item/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  addItem(ctx, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/app-widget/${idx}/add-item/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  removeItem(ctx, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/app-widget/${idx}/remove-item/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  create(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/app-widget/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  STORE_MODULE_NAME,
  namespaced: true,
  actions,
}
